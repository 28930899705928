import { createTheme } from "@mui/material/styles";  

export const ThemeMain = createTheme({

    palette: {
        primary: {
            light: '#333333',
            main: "#000000",
            dark:"#666666"
        },
        secondary:{
            light:'#eb336f',
            main:"#E7004C",
            dark:"#a10035"
        },
        white:{
            main: "#fff"
        },
    },
    typography: {
        fontFamily: ['Poppins','sans-serif',].join(','),
        fontSize: 16,
        fontWeight:'500'
    }

});

export const ThemeCustom = createTheme({
    palette: {
        primary: {
            light: '#333333',
            main: "#000000",
            disable:"#333333"
        },
        secondary:{
            light: '#3ab36b',
            main: "#09A047",
            disable:"#73c796"
        },
        white:{
            main: "#fff"
        }
    },
    typography: {
        text12: {
            color:  ThemeMain.palette.primary.main,
            fontSize: '0.75rem',
        },
        textBase:{
            color:  ThemeMain.palette.primary.main,
            fontSize: '1rem',
        },
        text20: {
            color:  ThemeMain.palette.primary.main,
            fontSize: '1.25rem',
        },
        text24: {
            color:  ThemeMain.palette.primary.main,
            fontSize: '1.5rem',
        },
        text28: {
            color:  ThemeMain.palette.primary.main,
            fontSize: '1.75rem',
        },
        text34: {
            color:  ThemeMain.palette.primary.main,
            fontSize: '2.125rem',
            fontWeight:'600 !important'
        },
        title30: {
            color:  ThemeMain.palette.white.main,
            fontFamily: `${['Bebas Neue','sans-serif',].join(',')} !important`,
            fontSize: '1.875rem',
            fontWeight:'400'
        },
        title40: {
            color:  ThemeMain.palette.white.main,
            fontFamily: `${['Bebas Neue','sans-serif',].join(',')} !important`,
            fontSize: '2.5rem',
            fontWeight:'400'
        },
        title100: {
            color:  ThemeMain.palette.white.main,
            fontFamily: `${['Bebas Neue','sans-serif',].join(',')} !important`,
            fontSize: '6.25rem',
            fontWeight:'400'
        },
        title130: {
            color:  ThemeMain.palette.secondary.main,
            fontFamily: `${['Bebas Neue','sans-serif',].join(',')} !important`,
            fontSize: '8.125rem',
            fontWeight:'400'
        },
    },
    components:{
        MuiTypography:{
            styleOverrides: {
                root: {
                    fontFamily: ['Poppins','sans-serif',].join(','),
                    fontWeight: 500,
                    lineHeight: 1.2,
                    textAlign:'center'
                }
            },
        },
        MuiButton:{
            variants: [
                {
                    props: { variant: 'secondaryBtn' },
                    style: {
                        color:  ThemeMain.palette.white.main,
                        fontFamily: ['Bebas Neue','sans-serif',].join(','),
                        fontSize: '1.875rem',
                        lineHeight: 1.2,
                        fontWeight: 400,
                        backgroundColor: ThemeMain.palette.secondary.main,
                        borderRadius: '0.75rem',
                        padding: '0.975rem 1.5rem',
                        textTransform: 'initial',
                        '&:hover': {
                            backgroundColor: ThemeMain.palette.secondary.light
                        },
                        '&.Mui-disabled': {
                           backgroundColor: `${ThemeMain.palette.secondary.dark} !important`,
                        }

                    },
                }
            ]  
        },
        MuiBackdrop:{
            styleOverrides: {
                root: {
                    backgroundColor: `rgba(0, 0, 0, 0.9)`,
                }
            }
        }
    }
});