import React, {useEffect, useState} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';

import { CssBaseline, ThemeProvider } from "@mui/material";
import {ThemeMain} from './style/Theme.js'

import '../src/style/custom_styles.scss';

import Home from './views/Home';
import NotFound from './views/Not_found';
import ErrorAlert from './components/alerts/Fail_alert.js';
import SuccessAlert from './components/alerts/Success_alert.js';

const BaseUrl = 'https://www.layupcloud.com/API'

function App() {
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openFail, setOpenFail] = useState(false)
  const [textSuccess, setTextSuccess] = useState('')
  const [textFail, setTextFail] = useState('')
  const location = useLocation();

  //alert handling

  useEffect(()=>{

    if(openSuccess){
      setTimeout(()=>{
        setOpenSuccess(false)
      },5000)
    }

  },[openSuccess])

  useEffect(()=>{

    if(openFail){
      setTimeout(()=>{
        setOpenFail(false)
      },5000)
    }

  },[openFail])

  //fix trailing slash

  // useEffect(() => {
  //   console.log('cc',location.pathname)

  //   if (location.pathname.endsWith('/')) {
  //     navigate(location.pathname.slice(0, -1), { replace: true });
  //     console.log('trailing')
  //   }

  //   else{
  //     console.log('trailing else')

  //   }

  // }, [location]);

  return (
    
    <ThemeProvider theme={ThemeMain}>
      <CssBaseline enableColorScheme/>
      <div className='absolute top-5 sm:right-5 z-[100] w-full px-5 sm:px-0'>
          <SuccessAlert
            openSuccess={openSuccess}
            setOpenSuccess={setOpenSuccess}
            textSuccess={textSuccess}
          />
          <ErrorAlert
            openFail={openFail}
            setOpenFail={setOpenFail}
            textFail={textFail}
          />
      </div>
      <Routes>
          {/* <Route path="/" element={<Navigate to="/kungfu-panda" replace />} /> */}
          <Route path='/' element={
            <Home 
              BaseUrl={BaseUrl}
              setOpenSuccess={setOpenSuccess}
              setOpenFail={setOpenFail}
              setTextSuccess={setTextSuccess}
              setTextFail={setTextFail}
            />
          }/>
          <Route path='*' element={<NotFound />}/>
      </Routes>  
    </ThemeProvider>  

  );
}

export default App;
