import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useLocation} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import {ThemeProvider, Typography, Container } from '@mui/material';
import {ThemeCustom} from "../style/Theme";

import BtnMain from '../components/button/BtnMain';
import ConfirmModal from '../components/modal/Modal_confirm';
import BookedModal from '../components/modal/Modal_booked';

import BgVideo from '../assets/video.mp4';
import Logo from '../assets/Kung Fu Panda 4 Logo.png'
import creativeLogo from '../assets/Creative Software Logo.svg'
import layupLogo from '../assets/Layup Logo.svg'


const Home = (props) => {

    const [openBooked, setOpenBooked] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [ticketAval, setTicketAval] = useState(0)
    const [ticketCount, setTicketCount]  = useState(0)
    const [userName, setUserName] = useState('Layup Admin')
    const [userEmail, setUserEmail] = useState('admin@getlayup.com')
    const [userId, setUserId] = useState('Layup Admin')
    const [token, setToken] = useState('')
    const [userValid, setUserValid] = useState(true)
    const location = useLocation()
    const isMobileHorizontal = useMediaQuery({ query: '(orientation: landscape) and (max-device-width: 932px)' });

    useEffect(()=>{
        console.clear()
        console.log("%cCaught ya! Sneaking around the console you won't find any hidden tickets! 😉", 'color: orange; font-weight: 600; font-size: 16px');       
    },[])

    //on load API reqs

    useEffect(()=>{

        //get token from url

        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        setToken(token);
        sessionStorage.setItem('token', token)

        //get user details

        axios
        .get(`${props.BaseUrl}/QR_get_user_details.php?site=creative&token=${token}`)
        .then((res)=>{
            let data = res.data;
            //console.log('get user', data)
            if(data.success){
                setTicketAval(data.data.tokenStatus)
                setUserName(data.data.fullName)
                setUserEmail(data.data.email)
                setUserId(data.data.userID)
                setUserValid(true)
            }
            else{
                //console.log(data.message)
                setUserValid(false)
                props.setOpenFail(true)
                props.setTextFail(data.message)
            }
        })
        .catch((err)=>{
            //console.log(err);
            props.setOpenFail(true);
            props.setTextFail('The server encounted an error and could not complete your request.')
        });

        //getTickets()
        

    },[])

    //get ticket count

    const getTickets = () => {
        axios
        .get(`${props.BaseUrl}/QR_ticket_count.php?site=creative`)
        .then((res)=>{
            let data = res.data;
            //console.log('ticket count', data)
            if(data.success){
                //console.log('ticket count', data.ticketCount)
                setTicketCount(data.data.ticketCount)
            }
            else{
                //console.log(data.message)
                props.setOpenFail(true)
                props.setTextFail(data.message)
            }
        })
        .catch((err)=>{
            //console.log(err);
            props.setOpenFail(true);
            props.setTextFail('The server encounted an error and could not complete your request.')
        });
    }

    //book ticket button action

    const bookTicket = () => {
        //console.log('bookTicket')
        if(ticketAval == 0){
            bookTicketAPI()
        }
        else{
            setOpenBooked(true)
        }
    }

    //book ticket api

    const bookTicketAPI = () => {

        axios
        .get(`${props.BaseUrl}/QR_generate_qr_code.php?site=creative&token=${token}`)
        .then((res)=>{
            let data = res.data;
            //console.log('ticket count', data)
            if(data.success){
                //console.log('ticket count', data.ticketCount)
                setOpenConfirm(true)
                //getTickets()
            }
            else{
                //console.log(data.message)
                setOpenBooked(true)
                props.setOpenFail(true)
                props.setTextFail(data.message)
                //getTickets()
            }
        })
        .catch((err)=>{
            //console.log(err);
            props.setOpenFail(true);
            props.setTextFail('The server encounted an error and could not complete your request.')
        });

    }

    return(
        <ThemeProvider theme={ThemeCustom}>
            <ConfirmModal
                openConfirm={openConfirm} 
                setOpenConfirm={setOpenConfirm}
                userName={userName}
                userEmail={userEmail}
            />
            <BookedModal 
                openBooked={openBooked} 
                setOpenBooked={setOpenBooked}
                userName={userName}
            />
            <div className='relative h-[100vh]'>
                <video autoPlay loop muted id='bg-video'>
                    <source src={BgVideo} type="video/mp4" />
                </video>
                <div className='absolute z-50 p-5 sm:p-8 top-0 left-0'>
                    <img src={creativeLogo} alt='creative logo' className='w-full max-w-[10rem]'/>
                </div>
                <div className='absolute z-50 p-5 sm:p-8 bottom-0 right-0'>
                    <div className='flex w-60 items-center justify-end'>
                        <Typography variant='text12' className='text-white mr-4 font-normal mt-1' component={'div'}>Powered by</Typography>
                        <img src={layupLogo} alt='layup logo' className='w-full max-w-[6rem]'/>
                    </div>
                </div>
                <div id='container' className='absolute h-full w-full flex justify-center items-center bg-primary bg-opacity-40 bg-gradient py-5'>
                    <Container maxWidth="false">
                        {!isMobileHorizontal ? (
                        <div className='flex flex-col justify-center items-center'>
                            <img src={Logo} alt='kung fu panda logo' className='w-full max-w-[30rem] mb-8'/>
                            <Typography variant='title30' className='text-white' component={'div'}>
                                Friday, March 8 <span className='px-2'> • </span> 5:00PM @ CCC - SCOPE CINEMAS
                            </Typography>
                            <Typography variant='title100' className='text-[4rem] sm:text-[6.25rem] my-2 sm:my-0' component={'div'}>Exclusive Screening</Typography>
                            <Typography variant='title30' className='text-white mb-5' component={'div'}>
                                FOR CREATIVE SOFTWARE
                            </Typography>
                            <Typography variant='text20' className='text-white mb-10' component={'div'}>By The Recreation Committee</Typography>
                            {ticketCount < 1 ? (
                                <>
                                    <Typography variant='title130'  className='text-[4rem] sm:text-[6.25rem] my-2 sm:my-0' component={'div'}>Sold out!</Typography>
                                    <Typography variant='title40' className='text-white mb-10' component={'div'}>Tickets gone faster than Po's dumplings!</Typography>
                                </>
                            ): (
                                <>
                                    <Typography variant='title30' className='text-white' component={'div'}>Only</Typography>
                                    <Typography variant='title130'  className='text-[4rem] sm:text-[6.25rem] my-2 sm:my-0' component={'div'}>{ticketCount}</Typography>
                                    <Typography variant='title40' className='text-white mb-10' component={'div'}>{ticketCount == 1 ? 'TICKET':'TICKETS'} AVAILABLE</Typography>
                                    {  userValid ? (
                                        <BtnMain btnName={'BOOK YOUR TICKET NOW'} type={'button'} variant={'secondaryBtn'} onClick={bookTicket}/>
                                    ):''
                                    }
                                </>
                            )}
                            
                        </div>
                        ):(
                            <Typography variant='text20' className='text-white mb-10' component={'div'}>Please rotate your device to portrait mode.</Typography>
                        )}
                    </Container>
                </div>
            </div>
        </ThemeProvider>
    )
}

export default Home;