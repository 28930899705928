import React, {useEffect, useState} from 'react';

import {ThemeProvider, Button } from '@mui/material';
import {ThemeCustom} from "../../style/Theme";

const BtnMain = (props) => {
    return(
        <ThemeProvider theme={ThemeCustom}>
            <Button variant={props.variant} type={props.type} className={props.classes} onClick={props.onClick} disabled={props.disabled}>
                {props.btnName}
            </Button>
        </ThemeProvider>
    )
}

export default BtnMain;