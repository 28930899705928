import React, {useEffect, useState} from 'react';
import {useNavigate } from 'react-router-dom';


import {ThemeProvider, Typography, Container } from '@mui/material';
import {ThemeCustom} from "../style/Theme";
import { useMediaQuery } from 'react-responsive';

import BtnMain from '../components/button/BtnMain';

import NotFound404 from '../assets/404.png';
import creativeLogo from '../assets/Creative Software Logo.svg'
import layupLogo from '../assets/Layup Logo.svg'

const NotFound = () => {

    const isMobileHorizontal = useMediaQuery({ query: '(orientation: landscape) and (max-device-width: 932px)' });
    const navigate = useNavigate()

    const backToHome = () => {

        const token = sessionStorage.getItem('token')
        navigate(`/kungfu-panda?token=${token}`)

    }
    
    useEffect(()=>{
        console.clear()
        console.log("%cCaught ya! Sneaking around the console you won't find any hidden tickets! 😉", 'color: orange; font-weight: 600; font-size: 16px');
    },[])

    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className='relative h-[100vh]'>
                <div className='absolute z-50 p-5 sm:p-8 top-0 left-0'>
                    <img src={creativeLogo} alt='creative logo' className='w-full max-w-[10rem]'/>
                </div>
                <div className='absolute z-50 p-5 sm:p-8 bottom-0 right-0'>
                    <div className='flex w-60 items-center justify-end'>
                        <Typography variant='text12' className='text-white mr-4 font-normal mt-1' component={'div'}>Powered by</Typography>
                        <img src={layupLogo} alt='layup logo' className='w-full max-w-[6rem]'/>
                    </div>
                </div>
                <div id='container' className='h-full w-full flex justify-center items-center bg-primary'>
                    <Container maxWidth="false">
                        <div className='flex flex-col justify-center items-center'>
                            <img src={NotFound404} alt='kung fu panda logo' className={`w-full ${isMobileHorizontal ? 'max-w-[15rem]':'max-w-[30rem]'} mb-8`}/>
                            <BtnMain btnName={'Back'} classes={'min-w-[20rem]'} onClick={backToHome} type={'button'} variant={'secondaryBtn'}/>
                        </div>
                    </Container>
                </div>
            </div>
        </ThemeProvider>
    )
}

export default NotFound;