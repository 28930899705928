import React, {useEffect, useState} from 'react';

import {Box, Typography, Modal} from '@mui/material';

import './Modal_styles.scss';

import BtnMain from '../button/BtnMain';

import ModalImg from '../../assets/Skadoosh.png'

const ConfirmModal = ({openConfirm, setOpenConfirm, userEmail, userName}) => {
    
    return(
        <div>
            <Modal
                open={openConfirm}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`modal-base`}> 
                    <div className={'modal-container'}>
                        <div className={'content-wrapper'}>
                            <div className={'icon-wrapper'}>
                                <img src={ModalImg} alt='panda' className={`modal-img`}/>
                            </div>
                                <Typography id="modal-modal-title" className={'modal-Header34 modal-text-white modal-mb-7 modal-mt-10'} align={'center'} variant="text28" component="div">
                                    Thank you {userName ? (userName.split(' ')[0]):''}!
                                </Typography>
                                <Typography className={'modal-Header24 modal-text-white modal-mb-7'} align={'center'} variant="text20" component="div">
                                    Your ticket for Kung Fu Panda 4 is officially locked in! Keep an eye on your Inbox ({userEmail}) for the QR code and further instructions.
                                </Typography>
                                <Typography className={'modal-Header24 modal-text-white modal-mb-10'} align={'center'} variant="text20" component="div">
                                    See you at the Show!
                                </Typography>
                        </div>
                        <div className={'footer-wrapper-center'}>
                            <BtnMain btnName="Skadoosh!" classes={'modal-w-full modal-text-24'} variant={'secondaryBtn'} type="submit" onClick={()=>setOpenConfirm(false)} disabled={false}/>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default ConfirmModal;