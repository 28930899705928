import React, {useEffect, useState} from 'react';

import {Box, Typography, Modal} from '@mui/material';

import './Modal_styles.scss';

import BtnMain from '../button/BtnMain';

import ModalImg from '../../assets/Okay.png'

const BookedModal = ({openBooked, setOpenBooked, userName}) =>{  

    return(
        <div>
            <Modal
                open={openBooked}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`modal-base`}> 
                    <div className={'modal-container'}>
                        <div className={'content-wrapper'}>
                            <div className={'icon-wrapper'}>
                                <img src={ModalImg} alt='panda' className={`modal-img`}/>
                            </div>
                                <Typography id="modal-modal-title" className={'modal-Header34 modal-text-white modal-mb-7 modal-mt-10 modal-mb-10'} align={'center'} variant="text28" component="div">
                                    {userName ? (userName.split(' ')[0]):''}, you have already booked your ticket!
                                </Typography>
                        </div>
                        <div className={'footer-wrapper-center'}>
                            <BtnMain btnName="Okay!" classes={'modal-w-full modal-text-24'} variant={'secondaryBtn'} type="submit" onClick={()=>setOpenBooked(false)} disabled={false}/>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default BookedModal;